"use client"

import { useGetUserQuery } from "@/hooks"
import mixpanel from "mixpanel-browser"

if (process.env.NEXT_PUBLIC_ENABLE_MIXPANEL === "true" && process.env.NEXT_PUBLIC_MIXPANEL_TOKEN) {
	mixpanel.init(
		process.env.NEXT_PUBLIC_MIXPANEL_TOKEN,
		process.env.NEXT_PUBLIC_ENABLE_MIXPANEL_COOKIE === "true"
			? {
					debug: false,
					track_pageview: false,
					persistence: "cookie",
					cross_subdomain_cookie: true,
				}
			: {
					debug: false,
					track_pageview: false,
					persistence: "localStorage",
				},
	)
}

export default function MixpanelInit() {
	// Render nothing - this component is only included so that the init code
	// above will run client-side
	const email = useGetUserQuery().data?.email

	if (email) {
		console.debug("Mixpanel: identity", email)
		mixpanel.identify(email)
	}

	return null
}
